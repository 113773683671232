import 'jquery-ui'

var $contentSection = $('#content-section');
var $contentSections = $('#content-section > section');
var $menuElements = $('nav').find('li a');

$('nav a').on('click', function(e) {
    e.preventDefault();

    var $this = $(this);
    var slug = $this.attr('href');
    var $section = $(slug);

    if ($section.length === 0) {
        console.error('Element not found', slug);
        return;
    }

    if (isMobileSize()) {
        var offsetTop = $section[0].offsetTop;
        var headerHeight = $('header').innerHeight() - ($('#wpadminbar').innerHeight() || 0);
        $('html, body').animate({
            scrollTop: offsetTop - headerHeight
        }, 300);

        $('nav').removeClass('show-mobile');
    } else {
        var offsetLeft = $section[0].offsetLeft;
        $contentSection.animate({
            scrollLeft: offsetLeft
        }, 300);
    }
});

$('.logo-link').click(function(e) {
    e.preventDefault();

    if (isMobileSize()) {
        $('html, body').animate({
            scrollTop: 0
        }, 300);

        $('nav').removeClass('show-mobile');
    } else {
        $contentSection.animate({
            scrollLeft: 0
        }, 300);
    }
});

function isMobileSize() {
    return window.innerWidth < 900;
}

var isScrolling;
var currentSection = -1;
updateMenuHighlight(0);

$contentSection.on('scroll', function(e) {
    var windowWidth = window.innerWidth;
    var scrollLeft = $(this)[0].scrollLeft;

    if (isScrolling) {
        clearTimeout(isScrolling);
    }

    isScrolling = setTimeout(function () {
        var offset = scrollLeft % windowWidth;
        if (Math.abs(offset) > 1) {
            var index = Math.floor(scrollLeft / windowWidth);
            if (offset > windowWidth / 2) {
                index += 1;
            }

            index = Math.min(index, $menuElements.length - 1);

            $contentSection.animate({
                scrollLeft: index * windowWidth
            }, 300);
        }
    }, 66);

    var index = Math.round(scrollLeft / windowWidth);

    updateMenuHighlight(index);
});

document.addEventListener('scroll', function() {
    if (!isMobileSize()) {
        return;
    }

    var index = 0;
    var headerHeight = $('header > div').innerHeight();
    var scrollTop = $(window).scrollTop() + headerHeight;

    for (var i = 0; i < $contentSections.length; i++) {
        var section = $contentSections[i];
        if (section.offsetTop <= scrollTop) {
            index = i;
        }
    }

    updateMenuHighlight(index);
});

function updateMenuHighlight(index) {
    if (index === currentSection) {
        return;
    }

    $menuElements.css('color', 'inherit');

    var $menuElement = $($menuElements[index - 1]);
    $menuElement.css('color', $menuElement.data('bgColor'));

    currentSection = index;
}

$('#hamburger').click(function(e) {
   e.preventDefault();

   $('nav').toggleClass('show-mobile');
});